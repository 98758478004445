<template>
  <div>
    <h1>Rexilience SCORE Chat</h1>
    <p><a :href="googleAuthUrl">Login Google</a></p>
  </div>
</template>

<script>
export default {
  name: 'HomeComponent',
  computed: {
    googleAuthUrl() {
      console.log(process.env.VUE_APP_API_URL + '/login/google');
      return process.env.VUE_APP_API_URL + '/login/google';
    },
  },
};
</script>
