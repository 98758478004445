<template>
  <div class="container">
    <HeaderComponent v-on:logout="logout"></HeaderComponent>
  <main>
    <div class="pricing-header p-3 pb-md-4 mx-auto text-center">
      <h1 class="display-4 fw-normal">Score Chat</h1>
      <div v-if="selectedSolution < 0">
        <p class="fs-5 text-muted" v-if="user">Benvenuto {{ user.displayName }}, seleziona un tema di tuo interesse</p>
        <CardSummary :solutions="solutions" v-model="selectedSolution"></CardSummary>
      </div>
      <div v-else class="container">
        <CardDetail :solution="solutions[selectedSolution]" v-on:chiudi-dettaglio="resetSolution"></CardDetail>
        <ChatComponent :solution="solutions[selectedSolution]" v-on:chiudi-dettaglio="resetSolution" v-on:logout="logout" ref="chat"></ChatComponent>
      </div>
    </div>
  </main>
  </div>
</template>

<script>
import CardDetail from './CardDetail.vue'
import ChatComponent from './Chat.vue'
import CardSummary from './CardSummary.vue'
import HeaderComponent from './Header.vue'

export default {
  name: "DashboardComponent",
  components: {
    CardDetail, ChatComponent, CardSummary, HeaderComponent
  },
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      selectedSolution: -1,
      solutions: []
    }
  },
  methods: {
    resetSolution() {
      this.$refs.chat.reset();
      this.selectedSolution = -1;
    },
    async logout() {
      try {
        const response = await fetch(process.env.VUE_APP_API_URL + "/logout", {
          credentials: "include",
        });

        if (response.ok) {
          localStorage.removeItem("user");
          this.$router.push({ name: "home" });
        } else {
          console.error("Logout failed:", response.status);
        }
      } catch (error) {
        console.error("Error during logout:", error);
      }
    },
    async getSolutions() {
      try {
        const response = await fetch(process.env.VUE_APP_API_URL + "/solutions", {
          credentials: "include",
        });

        if (response.ok) {
          this.solutions = await response.json();
        } else {
          console.error("Retrieving solutions failed:", response.status);
          this.logout();
        }
      } catch (error) {
        console.error("Error retrieving solutions:", error);
      }
    },
  },
  mounted() {
    if (!this.user) {
      this.logout();
    }
    this.getSolutions();
  },
};
</script>

<style>
.user-img {
  height: 2.5rem;
  width: 2.5rem;
  margin-right: 0.5rem;
  border-radius: 100%;
  vertical-align: unset;
}
.chat .card-header {
  background-color: white !important;
  border-bottom: 2px solid #f5f5f5 !important;
}
</style>