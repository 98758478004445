<template>
  <div class="container">
    <div class="row row-cols-1 row-cols-md-3 mb-3 text-center">
      <div v-for="(solution, index) in solutions" :key="solution.name" class="col">
        <div class="card mb-4 rounded-3 shadow-sm">
        <div class="card-header py-3">
          <h4 class="my-0 fw-normal">{{ solution.name }}</h4>
        </div>
        <div class="card-body">
          <h1 class="card-title pricing-card-title">{{ solution.title }}</h1>
          <ul class="list-unstyled mt-3 mb-4">
            <li v-html="solution.abstract"></li>
          </ul>
          <button type="button" class="w-100 btn btn-lg btn-outline-primary"
            @click.prevent="select(index)">Approfondisci</button>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardSummary',
  props: {
    solutions: {
      type: Array,
      default() {
        return []
      }
    },
    modelValue: {
      type: Number,
      default: -1
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
    }
  },
  methods: {
    select(index) {
      this.$emit('update:modelValue', index);
    }
  }
};
</script>