import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import moment from 'moment';
import { library } from "@fortawesome/fontawesome-svg-core";
import { faClock} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faClock);

moment.locale('it');

const app = createApp(App);
app.provide("moment", moment);
app.use(router);
app.component("font-awesome-icon", FontAwesomeIcon);
app.directive('focus', {
  // When the bound element is mounted into the DOM...
  mounted(el) {
    // Focus the element
    el.focus()
  }
})

app.mount('#app');
