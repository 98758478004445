<template>
  <div class="container">
    <div class="col text-start" style="padding-top: 10px;">
      <div class="w-100 m-4">
        <h3>Puoi chiedere ulteriori informazioni a Rexi, il nostro assistente virtuale</h3>
        <p>&Egrave; in grado di comprendere le tue domande e rispondere in modo efficace, fornendoti informazioni dettagliate su una vasta gamma di argomenti. L'obiettivo principale di questo assistente è aiutare manager, professionisti IT, amministratori di sistema, e utenti finali a proteggere le loro infrastrutture digitali e risorse.</p>
      </div>
      <ul class="list-unstyled">
        <li v-for="(message, index) in filteredMessages"
          :key="index" class="chat d-flex justify-content-between mb-4">
          <img v-if="message.role == 'assistant'" src="favicon.png" alt="avatar"
            class="rounded-circle d-flex align-self-start me-3 shadow-1-strong" width="60">
          <div class="card w-100">
            <div class="card-header d-flex justify-content-between p-3">
              <p class="fw-bold mb-0">{{ message.name }}</p>
              <p class="text-muted small mb-0"><font-awesome-icon icon="clock" style="color:lightgray" /> {{ moment(message.created).format('LL - H:mm') }}</p>
            </div>
            <div class="card-body">
              <p class="mb-0" v-html="message.text" v-bind:class="{'text-start': message.role == 'assistant',  'text-end': message.role != 'assistant', 'text-blink': thinking && message.role == 'assistant' && index == filteredMessages.length - 1}"></p>
            </div>
          </div>
          <img v-if="message.role == 'user'" :src="user.picture" alt="avatar"
            class="rounded-circle d-flex align-self-start ms-3 shadow-1-strong" width="60">
        </li>
        <li v-if="! thinking" class="bg-white mb-3">
          <div class="form-outline">
            <textarea v-focus ref="input" class="form-control" id="textAreaExample2" rows="4"
              v-model="domanda"
              @keydown.enter.exact.prevent
              @keyup.enter.exact="chiedi"></textarea>
            <label class="form-label" for="textAreaExample2">Fai una domanda all'assistente virtuale</label>
          </div>
          <button @click.prevent="chiedi()" type="button" class="btn btn-primary btn-rounded float-end">Invia</button>
          <button type="button" class="btn btn-outline-primary btn-rounded me-3 float-end" @click.prevent="$emit('chiudi-dettaglio')">Chiudi</button>
        </li>
        <div v-if="thinking" class="w-100 text-center">
        <div id="spinner" class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      </ul>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue';

export default {
  name: 'ChatComponent',
  props: {
    solution: {}
  },
  setup() {
    const moment = inject('moment');
    return { moment };
  },
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      thinking: false,
      domanda: "",
      answeringMessage: -1,
      clearChatFailed: false,
      messages: [
        {
          role: "assistant",
          name: "Rexi Assistente Virtuale",
          text: "Ciao, sono Rexi, il tuo assistente virtuale. Come posso aiutarti?",
          created: new Date()
        }
      ]
    }
  },
  mounted() {
    console.log("mounted");
    this.domanda = "Gentilmente, fammi un esempio di 5 domande che potrei fare su questo argomento.";
    this.clearChatFailed = false;
    this.chiediCinque();
  },
  computed: {
    filteredMessages() {
      return this.messages.filter((val, ix) => {return ix!=1 || this.clearChatFailed;});
    }
  },
  methods: {
    reset() {
      this.messages = this.messages.slice(0, 1);
      fetch(process.env.VUE_APP_API_URL + "/clear-chat", {
        credentials: "include",
      });
    },
    focusInput() {
      this.$nextTick(() => {this.$refs.input.focus()});
    },
    onStreamClose() {
      console.log("Stream closed");
      this.thinking = false;
      this.reader = null;
      this.focusInput();
    },
    async chiediCinque() {
      console.log("Chiedo cinque domande: "+this.solution.type);
      try {
        // fa la richiesta per le cinque domande (che sono state precedentemente generate)
        const response = await fetch(process.env.VUE_APP_API_URL + "/fake-chat?type="+this.solution.type, {
          credentials: "include",
        });

        if (response.ok) {
          let data = await response.json();
          if (data.text.length == 0) {
            // questo flag mostra la domanda che altrimenti è nascosta dal filter nel v-for
            this.clearChatFailed = true;
            // se la risposta non era presente generala
            console.log("risposta non presente")
            return this.chiedi();
          }
          // mostra i messaggi
          this.messages.push({
            role: "user",
            name: this.user.displayName,
            text: this.domanda,
            created: new Date()
          });
          this.messages.push({
            role: this.messages[0].role,
            name: this.messages[0].name,
            text: data.text.replace(/\n/g, "<br>"),
            created: new Date()
          });
          this.domanda = "";
          // forza scroll e il focus sull'input
          window.setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
            this.focusInput();
          }, 1000);
        } else {
          console.error("Retrieving solutions failed:", response.status);
          this.logout();
        }
      } catch (error) {
        console.error("Error retrieving solutions:", error);
      }

    },
    async processStream() {
      // processa la lettura della risposta man mano che viene inviata da openai
      if (! this.reader) {
        console.log("reader is null")
        this.onStreamClose();
        return;
      }
      try {
        const { value } = await this.reader.read();
        if (value === undefined) {
          console.log("value is undefined")
          this.onStreamClose();
          return;
        }
        const text = new TextDecoder("utf-8").decode(value);
        const chunks = [...text.matchAll(/data: (.*)\n\n/g)]
        for (var i = 0; i < chunks.length; i++) {
          const chunk = chunks[i][1];
          if (chunk.indexOf("[DONE]") >= 0) {
            this.onStreamClose();
            return;
          }
          const data = JSON.parse(chunk)
          if (data.choices[0].delta.content) {
            const text = data.choices[0].delta.content.replace(/\n/g, "<br>");
            if (this.answeringMessage >= 0) {
              this.messages[this.answeringMessage].text += text;
              window.scrollTo({
                top: document.body.scrollHeight || document.documentElement.scrollHeight,
                left: 0,
                behavior: "instant",
              });
              //window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
            } else {
              console.log("answeringMessage is negative")
            }
          }
        }
      } catch (error) {
        console.error("Error:", error);
        this.onStreamClose();
            return;
      }
      this.$nextTick(function() {this.processStream()});
    },
    async chiedi() {
      if (this.thinking) {
        return;
      }
      if (this.domanda.length < 3) {
        this.focusInput();
        return;
      }
      console.log("domanda.length: " + this.domanda.length);
      this.thinking = true;
      this.messages.push({
        role: "user",
        name: this.user.displayName,
        text: this.domanda,
        created: new Date()
      });
      this.messages.push({
        role: this.messages[0].role,
        name: this.messages[0].name,
        text: "",
        created: new Date()
      });
      this.$nextTick(function() {
        window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
      });

      this.answeringMessage = this.messages.length -1;
      const params = new URLSearchParams();
      params.append("type", this.solution.type);
      params.append("prompt", this.domanda);
      this.domanda = "";
      try {
        const response = await fetch(process.env.VUE_APP_API_URL + "/chat?"+params.toString(), {
          credentials: "include",
        });
        console.log("Got response: ", response.status, response.statusText);
        if (response.status == 401) {
          this.$emit("logout")
          return;
        }
        if (!response.body) {
          console.error("Fetch failed: Response body is empty");
          return;
        }

        const reader = response.body.getReader();
        this.reader = reader;
        this.processStream();
      } catch (error) {
        console.error("Error:", error);
        this.onStreamClose();
      }
    },
  }
};
</script>
<style scoped>
#chat {
  height: 100%;
  display: flex;
  flex-direction: column;
}

@keyframes cursor-blink {
  0% {
    opacity: 0;
  }
}

p.text-blink::after {
  content: "";
  width: 10px;
  height: 17px;
  background: rgb(0, 0, 0);
  display: inline-block;
  animation: cursor-blink 1.5s steps(2) infinite;
}
</style>
```