<template>
  <div class="container">
    <div class="row" style="padding-bottom: 15px;">
      <h2>{{ solution.title }}
        <button type="button" class="btn btn-lg btn-outline-primary" @click.prevent="$emit('chiudi-dettaglio')">Chiudi</button>
      </h2>
    </div>
    <div class="card p-4">
      <div class="text-start" v-for="(desc, index) in description" :key="index">
        <h3>{{desc.title}}</h3>
        <p class="text-start" v-for="(paragraph, index) in desc.text" :key="index" v-html="paragraph"></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardDetail',
  props: {
    solution: {}
  },
  data() {
    return {
      description: [],
    }
  },
  methods: {
    async getSolution(type) {
      try {
        const response = await fetch(process.env.VUE_APP_API_URL + "/solution?name=" + type, {
          credentials: "include",
        });

        if (response.ok) {
          this.description = await response.json();
        } else {
          console.error("Get solution failed:", response.status);
        }
      } catch (error) {
        console.error("Error during get solution:", error);
      }
    }
  },
  mounted() {
    console.log(this.solution);
    this.getSolution(this.solution.type);
  }
};
</script>