<template>
    <div>
      <h1>Logout</h1>
      <p>You have been logged out.</p>
      <router-link to="/">Go to Home</router-link>
    </div>
  </template>

  <script>
  export default {
    name: "LogoutComponent",
    async created() {
      try {
        const response = await fetch(process.env.VUE_APP_API_URL + "/logout", {
          credentials: "include",
        });

        if (response.ok) {
          localStorage.removeItem("user");
          this.$router.push({ name: "home" });
        } else {
          console.error("Logout failed:", response.status);
        }
      } catch (error) {
        console.error("Error during logout:", error);
      }
    },
  };
  </script>
