<template>
  <div>
    <h1>Login Page</h1>
    <a :href="googleAuthUrl">Login with Google</a>
  </div>
</template>

<script>
export default {
  name: 'LoginComponent',
  computed: {
    googleAuthUrl() {
      return process.env.VUE_APP_API_URL + '/login/google';
    },
  },
};
</script>
