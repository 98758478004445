<template>
    <header>
        <div class="d-flex flex-column flex-md-row align-items-center pb-3 mb-4 border-bottom">
            <a href="/" class="d-flex align-items-center text-dark text-decoration-none">
            <img src="favicon.png"  width="40" height="32" class="me-2">
            <span class="fs-4">REXILIENCE</span>
            </a>

            <nav class="d-inline-flex mt-2 mt-md-0 ms-md-auto">
            <div class="d-block me-3 ">
                <img referrerPolicy="no-referrer" :src="user.picture" :alt="user.displayName" width="32" height="32" class="rounded-circle">
            </div>
            <div class="py-2 text-dark text-decoration-none">{{ user.email }} <a @click.prevent="$emit('logout')" href="#">Logout</a></div>
            </nav>
        </div>
  </header>
</template>
<script>
export default {
  name: 'HeaderComponent',
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
    }
  }
}
</script>
